import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import './Layout.css';

const Layout: React.FC = () => (
    <div className="d-flex flex-column min-vh-100">
        <Header />
        <Container fluid className="flex-grow-1 p-0">
            <Row noGutters className="flex-nowrap">
                <Sidebar />
                <div className="content-col">
                    <Outlet />
                </div>
            </Row>
        </Container>
        <Footer />
    </div>
);

export default Layout;
