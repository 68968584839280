import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ModalAlertProps {
    isOpen: boolean;
    onClose: () => void;
    message: string;
    type: 'success' | 'error';
}

const ModalAlert: React.FC<ModalAlertProps> = ({ isOpen, onClose, message, type }) => {
    return (
        <Modal show={isOpen} onHide={onClose} centered>
            <Modal.Header closeButton className={`modal-header-${type}`}>
                <Modal.Title>{type === 'success' ? 'Éxito' : 'Error'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`modal-body-${type}`}>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAlert;
