import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './modaleTable.css';
interface ConfirmModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    message: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ isOpen, onClose, onConfirm, message }) => {
    return (
        <Modal show={isOpen} onHide={onClose}>
            <Modal.Header closeButton className="modal-header-custom">
                <Modal.Title className="modal-title-custom">Confirmar Cierre</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-secondary" onClick={onClose}>
                    Cancelar
                </Button>
                <Button className="btn btn-custom" onClick={onConfirm}>
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;
