import React, { useEffect, useState } from 'react';
import fetchApi from '../../api/fetchApi';
import { Button, Table } from 'react-bootstrap';
import { FaSquarePlus } from "react-icons/fa6";
import { FaSearch } from 'react-icons/fa';
import {  IoMdCloseCircleOutline, IoMdEye } from "react-icons/io";
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { IoCloudDownloadSharp } from "react-icons/io5";
import ModalTable from './modalTable';
import './Dashboard.css';
import ConfirmModal from './ConfirmModal';
import { useNavigate } from 'react-router-dom';


interface Pais { paiNombre: string; }
interface Cliente { cliNombre: string; }
interface Comercial { persona: Persona; }
interface Persona { perNombre: string; perApellido: string; }
interface Sociedad { socNobre: string; }
interface File { data: string; }
interface Operacion {
    ope_id: string;
    ope_codigo: string;
    ope_descripcion: string;
    ope_fecha_inicio: string;
    ope_fecha_fin: string;
    ope_orden_compra: string;
    ope_ceco: string;
    jef_id: string;
    pais: Pais;
    cliente: Cliente;
    comercial: Comercial;
    sociedad: Sociedad;
    file: File;
}

interface JefeProyecto { jefCodigo: string; persona: { perNombre: string; perApellido: string; }; }

const Dashboard: React.FC = () => {
    const [operaciones, setOperaciones] = useState<Operacion[]>([]);
    const [jefesProyectos, setJefesProyectos] = useState<JefeProyecto[]>([]);
    const [busqueda, setBusqueda] = useState<string>('');
    const [modelTabs, setModelTabs] = useState<number>(0);
    const [pagination, setPagination] = useState({ page: 1, size: 20, numberPages: 1 });
    const [loading, setLoading] = useState<boolean>(false);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [modalData, setModalData] = useState<string[][]>([]);
    const [animateTable, setAnimateTable] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [operationToClose, setOperationToClose] = useState<Operacion | null>(null);
    const [confirmMessage, setConfirmMessage] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchOperaciones();
        fetchJefesProyectos();
    }, [modelTabs, pagination.page]);

    useEffect(() => {
        setAnimateTable(false);
        setTimeout(() => setAnimateTable(true), 100);
    }, [modelTabs]);

    const fetchOperaciones = async () => {
        setLoading(true);
        try {
            const status_id = modelTabs === 0 ? 1 : 4;
            const response = await fetchApi({
                endpoint: '/projectos/list',
                method: 'POST',
                body: { size: pagination.size, page: pagination.page, status_id }
            });
            setOperaciones(response.content);
            setPagination(prev => ({ ...prev, numberPages: response.totalPages }));
        } catch (error) {
            console.error('Error al obtener los datos de operaciones:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchJefesProyectos = async () => {
        try {
            const response = await fetchApi({ endpoint: '/jefesProyectos', method: 'GET' });
            setJefesProyectos(response);
        } catch (error) {
            console.error('Error al obtener los datos de jefes de proyectos:', error);
        }
    };

    const operacionesFiltradas = operaciones.filter(operacion =>
        operacion.ope_orden_compra.toLowerCase().includes(busqueda.toLowerCase())
    );

    const getJefeOperacion = (jef_id: string) => {
        const jefe = jefesProyectos.find(jefe => jefe.jefCodigo === jef_id);
        return jefe ? `${jefe.persona.perNombre} ${jefe.persona.perApellido}` : 'No asignado';
    };

    const handlePaginationChange = (page: number) => {
        setPagination(prev => ({ ...prev, page }));
    };

    const handleOpenModal = (data: string) => {
        try {
            if (!data) {
                setModalData([["No hay datos para mostrar"]]);
            } else {
                const parsedData = JSON.parse(data);
                setModalData(parsedData);
            }
            setModalIsOpen(true);
        } catch (error) {
            setModalData([["No se puede mostrar porque hay un error en los datos"]]);
            setModalIsOpen(true);
        }
    };
    const handleCloseOperationClick = (operacion: Operacion) => {
        const pgyData = operacion.file.data;

        if (!pgyData || pgyData === 'No hay datos para mostrar' || pgyData === 'No se puede mostrar porque hay un error en los datos') {
            setConfirmMessage(
                '¿Estás seguro de cerrar la operación? ten en cuenta que el P&G está vacío o tiene errores.'
            );
        } else {
            setConfirmMessage('¿Estás seguro de cerrar la operación?');
        }

        setOperationToClose(operacion);
        setShowConfirmModal(true);
    };

    const handleConfirmCloseOperation = async () => {
        if (operationToClose) {
            try {
                const response = await fetchApi({
                    endpoint: '/projectos/close',
                    method: 'POST',
                    body: {
                        notificaciones: false,
                        observaciones: "Operación cerrada",
                        project_id: operationToClose.ope_id,
                        status_id: 4
                    }
                });
                fetchOperaciones();
            } catch (error) {
                console.error('Error al cerrar la operación:', error);
            } finally {
                setShowConfirmModal(false);
                setOperationToClose(null);
            }
        }
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
        setModalData([]);
    };

    return (
        <div className="dashboard-container">
            {/* Barra de búsqueda y botones */}
            <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="search-wrapper">
                    <label htmlFor="search" className="search-label">Buscar</label>
                    <div className="search-field">
                        <FaSearch className="search-icon" />
                        <input
                            type="text"
                            placeholder="Buscar..."
                            className="search-input"
                            value={busqueda}
                            onChange={(e) => setBusqueda(e.target.value)}
                            id="search"
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <FaSquarePlus className="create-button ms-2" onClick={() => navigate('/crud/createProyect')} />
                    <IoCloudDownloadSharp className="download-button ms-2" onClick={() => {/* Lógica de descarga */}} />
                </div>
            </div>

            <div className="d-flex align-items-center mb-4">
                <Button
                    onClick={() => { setModelTabs(0); setPagination(prev => ({ ...prev, page: 1 })); }}
                    className={`bottom-tab ${modelTabs === 0 ? 'active' : ''}`}
                >
                    OPERACIONES ABIERTAS
                </Button>
                <Button
                    onClick={() => { setModelTabs(1); setPagination(prev => ({ ...prev, page: 1 })); }}
                    className={`bottom-tab ${modelTabs === 1 ? 'active' : ''}`}
                >
                    OPERACIONES CERRADAS
                </Button>
            </div>

            {/* Tabla de operaciones */}
            <SimpleBar style={{ maxHeight: '600px' }}>
                <Table className={animateTable ? 'slide-up-animation' : ''}>
                    <thead className="head_block">
                    <tr>
                        {modelTabs === 0 && <td className="head_t">Acciones</td>}
                        <td className="head_t">ID</td>
                        <td className="head_t">Código de la operación</td>
                        <td className="head_t">P&G</td>
                        <td className="head_t">Descripción de la operación</td>
                        <td className="head_t">Fecha de inicio</td>
                        <td className="head_t">Fecha de finalización</td>
                        <td className="head_t">Orden de compra / Contrato</td>
                        <td className="head_t">Jefe de operación</td>
                        <td className="head_t">Cliente</td>
                        <td className="head_t">Comercial</td>
                        <td className="head_t">País</td>
                        <td className="head_t">Sociedad</td>
                        <td className="head_t">CECO</td>
                    </tr>
                    </thead>
                    <tbody>
                    {operacionesFiltradas.length > 0 ? (
                        operacionesFiltradas.map((operacion, index) => (
                            <tr key={index}>
                                {modelTabs === 0 && (
                                    <td className="table_dashboard">
                                        <IoMdCloseCircleOutline
                                            className="bottom-size bottom-red"
                                            onClick={() => handleCloseOperationClick(operacion)}
                                        />
                                    </td>
                                )}
                                <td className="table_dashboard">{operacion.ope_id}</td>
                                <td className="table_dashboard">{operacion.ope_codigo}</td>
                                <td className="table_dashboard">
                                    <IoMdEye
                                        className="bottom-size bottom-green"
                                        onClick={() => handleOpenModal(operacion.file.data)}
                                    />
                                </td>
                                <td className="table_dashboard">{operacion.ope_descripcion}</td>
                                <td className="table_dashboard">{operacion.ope_fecha_inicio}</td>
                                <td className="table_dashboard">{operacion.ope_fecha_fin}</td>
                                <td className="table_dashboard">{operacion.ope_orden_compra}</td>
                                <td className="table_dashboard">{getJefeOperacion(operacion.jef_id)}</td>
                                <td className="table_dashboard">{operacion.cliente.cliNombre}</td>
                                <td className="table_dashboard">
                                    {operacion.comercial.persona.perNombre} {operacion.comercial.persona.perApellido}
                                </td>
                                <td className="table_dashboard">{operacion.pais.paiNombre}</td>
                                <td className="table_dashboard">{operacion.sociedad.socNobre}</td>
                                <td className="table_dashboard">{operacion.ope_ceco}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={13} className="text-center">
                                No se encontraron operaciones
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </SimpleBar>
            {pagination.numberPages > 1 && (
                <div className="custom-pagination">
                    <button
                        className="pagination-prev"
                        onClick={() => handlePaginationChange(pagination.page - 1)}
                        disabled={pagination.page === 1}
                    >
                        &lt;
                    </button>

                    {(() => {
                        const maxPagesToShow = 10;
                        const currentPage = pagination.page;
                        const totalPages = pagination.numberPages;

                        const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
                        const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

                        const adjustedStartPage = Math.max(1, endPage - maxPagesToShow + 1);

                        return Array.from({length: endPage - adjustedStartPage + 1}, (_, index) => {
                            const page = adjustedStartPage + index;
                            return (
                                <button
                                    key={page}
                                    className={`custom-pagination-item ${currentPage === page ? 'active' : ''}`}
                                    onClick={() => handlePaginationChange(page)}
                                >
                                    {page}
                                </button>
                            );
                        });
                    })()}

                    <button
                        className="pagination-next"
                        onClick={() => handlePaginationChange(pagination.page + 1)}
                        disabled={pagination.page === pagination.numberPages}
                    >
                        &gt;
                    </button>
                </div>
            )}

            {/* Modal p&g*/}
            {modalIsOpen && (
                <ModalTable
                    isOpen={modalIsOpen}
                    onClose={handleCloseModal}
                    data={modalData}
                />
            )}
            {/* Modal de confirmación */}
            {showConfirmModal && (
                <ConfirmModal
                    isOpen={showConfirmModal}
                    onClose={() => setShowConfirmModal(false)}
                    onConfirm={handleConfirmCloseOperation}
                    message={confirmMessage}
                />
            )}
            {loading && <LoadingOverlay />}
        </div>
    );
};

export default Dashboard;
