import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import fetchApi from '../../api/fetchApi';
import * as Yup from 'yup';
import * as XLSX from 'xlsx';
import { AxiosError } from 'axios';
import '../user/formStyles.css';
import {FaEye, FaRegFileExcel} from "react-icons/fa";
import { yupResolver } from '@hookform/resolvers/yup';
import ModalTable from './modalTable';
import ModalAlert from '../template/ModalAlert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface FormData {
    cliente: number;
    comercial: number;
    file: string;
    jef_id: string;
    opeCambioDivisaEuro?: number;
    opeCambioDivisaUsd?: number;
    ope_bl_id: number;
    ope_bu_id: number;
    ope_ceco: number;
    ope_codigo: string;
    ope_descripcion: string;
    ope_divisa_id: number;
    ope_eac_cost: number;
    ope_eac_revenue: number;
    ope_fecha_fin: Date;
    ope_fecha_inicio: Date;
    ope_garantia: boolean;
    ope_nombre?: string;
    ope_ol_id: number;
    ope_orden_compra: string;
    ope_tiempo_garantia?: number;
    ope_tipo_id: number;
    pais: string;
    sociedad: number;
    status_id?: number;
}
interface ExcelData {
    columnas: number;
    filas: number;
    data: string;
}

const validationSchema = Yup.object().shape({
    ope_orden_compra: Yup.string().required('Este campo es requerido'),
    pais: Yup.string().required('Este campo es requerido'),
    sociedad: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    cliente: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    ope_descripcion: Yup.string().required('Este campo es requerido'),
    comercial: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    jef_id: Yup.string().required('Este campo es requerido'),
    ope_codigo: Yup.string().required('Este campo es requerido'),
    ope_ceco: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    ope_bl_id: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    ope_bu_id: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    ope_ol_id: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    ope_divisa_id: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    ope_eac_revenue: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    ope_eac_cost: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    ope_tipo_id: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    ope_fecha_inicio: Yup.date()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    ope_fecha_fin: Yup.date()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    ope_garantia: Yup.boolean()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    file: Yup.string().required('Este campo es requerido'),
});


interface FormField {
    label: string;
    type: string;
    options?: { label: string; value: any }[];
}

const formFields: Partial<Record<keyof FormData, FormField>> = {
    ope_orden_compra: { label: 'Orden de Compra', type: 'text' },
    pais: { label: 'País', type: 'select' },
    sociedad: { label: 'Sociedad', type: 'select' },
    cliente: { label: 'Cliente', type: 'select' },
    ope_descripcion: { label: 'Descripción', type: 'textarea' },
    comercial: { label: 'Comercial', type: 'select' },
    jef_id: { label: 'Jefe de Operación', type: 'select' },
    ope_garantia: { label: '¿Tiene garantía?', type: 'select' },
    ope_tiempo_garantia: { label: '', type: '' },
    ope_fecha_inicio: { label: 'Fecha de Inicio', type: 'date' },
    ope_fecha_fin: { label: 'Fecha de Finalización', type: 'date' },
    ope_codigo: { label: 'Código de la Operación', type: 'text' },
    ope_ceco: { label: 'CECO', type: 'number' },
    ope_bl_id: { label: 'BL (Línea de Negocio)', type: 'select' },
    ope_bu_id: { label: 'BU (Unidad de Negocio)', type: 'select' },
    ope_ol_id: { label: 'OL (Organización Legal)', type: 'select' },
    ope_divisa_id: { label: 'Divisa', type: 'select' },
    ope_eac_revenue: { label: 'EAC Revenue', type: 'number' },
    ope_eac_cost: { label: 'EAC Cost', type: 'number' },
    ope_tipo_id: { label: 'Tipo de Operación', type: 'select' },
    file: { label: 'Excel P&G', type: 'file' },
};

const CrudProject = () => {
    const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm<FormData>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ope_nombre: '',
            opeCambioDivisaEuro: 0,
            opeCambioDivisaUsd: 0,
            status_id: 1
        }
    });

    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [excelData, setExcelData] = useState<ExcelData>({ columnas: 0, filas: 0, data: "" });
    const [paises, setPaises] = useState<any[]>([]);
    const [sociedades, setSociedades] = useState<any[]>([]);
    const [clientes, setClientes] = useState<any[]>([]);
    const [comerciales, setComerciales] = useState<any[]>([]);
    const [jefes, setJefes] = useState<any[]>([]);
    const [bls, setBl] = useState<any[]>([]);
    const [divisas, setDivisas] = useState<any[]>([]);
    const [tipos, setTipos] = useState<any[]>([]);
    const [tiempos, setTiempos] = useState<any[]>([]);
    const [bus, setBu] = useState<any[]>([]);
    const [ols, setOl] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>('');
    const [modalAlertOpen, setModalAlertOpen] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertType, setAlertType] = useState<'success' | 'error'>('success');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [hasGuarantee, setHasGuarantee] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            const paisesData = await fetchApi({ endpoint: '/paises', method: 'GET' });
            const sociedadesData = await fetchApi({ endpoint: '/sociedades', method: 'GET' });
            const clientesData = await fetchApi({ endpoint: '/clientes', method: 'GET' });
            const comercialesData = await fetchApi({ endpoint: '/comerciales', method: 'GET' });
            const jefesData = await fetchApi({ endpoint: '/jefesProyectos', method: 'GET' });
            const blData = await fetchApi({ endpoint: '/propiedades/146', method: 'GET' });
            const divisaData = await fetchApi({ endpoint: '/propiedades/100', method: 'GET' });
            const tipoData = await fetchApi({ endpoint: '/propiedades/110', method: 'GET' });
            const tiemposData = await fetchApi({ endpoint: '/propiedades/160', method: 'GET' });

            setPaises(paisesData || []);
            setSociedades(sociedadesData || []);
            setClientes(clientesData || []);
            setComerciales(comercialesData || []);
            setJefes(jefesData || []);
            setBl(blData || []);
            setDivisas(divisaData || []);
            setTipos(tipoData || []);
            setTiempos(tiemposData || []);
        };

        fetchData();
    }, []);


    const handleBlChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedBlId = e.target.value;
        setValue('ope_bl_id', parseInt(selectedBlId, 10));
        //console.log(selectedBlId);
        try {
            const buData = await fetchApi({
                endpoint: `/propiedades/${selectedBlId}`,
                method: 'GET'
            });
            setBu(buData);
        } catch (error) {
            console.error("Error fetching BU data:", error);
        }
    };

    const handleBuChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedBuId = e.target.value;
        setValue('ope_ol_id', parseInt(selectedBuId, 10));

        try {
            const olData = await fetchApi({
                endpoint: `/propiedades/${selectedBuId}`,
                method: 'GET'
            });
            setOl(olData);
        } catch (error) {
            console.error("Error fetching BU data:", error);
        }
    };
    const handleGuaranteeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value === "true";
        setHasGuarantee(value);
    };

    const formatFecha = (date: Date | null): string => {
        if (!date) return '';
        return date.toISOString().slice(0, 19);
    };

    const onSubmit: SubmitHandler<FormData> = async (formData) => {
        setIsSubmitting(true);

        const transformedData = {
            cliente: { cliId: Number(formData.cliente) || 0 },
            comercial: { comId: Number(formData.comercial) || 0 },
            file: {
                columnas: 0,
                data: excelData?.data || '',
                filas: Number(excelData?.filas) || 0,
            },
            jef_id: formData.jef_id || '',
            opeCambioDivisaEuro: Number(formData.opeCambioDivisaEuro) || 0,
            opeCambioDivisaUsd: Number(formData.opeCambioDivisaUsd) || 0,
            ope_bl_id: Number(formData.ope_bl_id) || 0,
            ope_bu_id: Number(formData.ope_bu_id) || 0,
            ope_ceco: Number(formData.ope_ceco) || 0,
            ope_codigo: formData.ope_codigo || '',
            ope_descripcion: formData.ope_descripcion || '',
            ope_divisa_id: Number(formData.ope_divisa_id) || 0,
            ope_eac_cost: Number(formData.ope_eac_cost) || 0,
            ope_eac_revenue: Number(formData.ope_eac_revenue) || 0,
            ope_fecha_fin: formatFecha(formData.ope_fecha_fin),
            ope_fecha_inicio: formatFecha(formData.ope_fecha_inicio),
            ope_garantia: formData.ope_garantia === true,
            ope_nombre: formData.ope_nombre || '',
            ope_ol_id: Number(formData.ope_ol_id) || 0,
            ope_orden_compra: formData.ope_orden_compra || '',
            ope_tiempo_garantia: Number(formData.ope_tiempo_garantia) || 0,
            ope_tipo_id: Number(formData.ope_tipo_id) || 0,
            pais: { paiCodigo: formData.pais || '' },
            sociedad: { socId: Number(formData.sociedad) || 0 },
            status_id: 1
        };

        //console.log('Datos a enviar:', JSON.stringify(transformedData, null, 2));

        try {
            await fetchApi({
                endpoint: '/projectos',
                method: 'POST',
                body: transformedData,
                useToken: true,
            });
            setAlertMessage('Operación creada con éxito.');
            setAlertType('success');
            setTimeout(() => {
                navigate('/operation/dashboard');
            }, 3000);
        } catch (error) {
            setAlertMessage('Error al crear la operación.');
            setAlertType('error');
            if (error instanceof AxiosError) {
                console.error('Error al enviar el formulario:', error.response?.data || error.message);
            } else {
                console.error('Error al enviar el formulario:', error);
            }
        } finally {
            setIsSubmitting(false);
            setModalAlertOpen(true);
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setFileName(file.name);

            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as any[][];
                const excelData: ExcelData = {
                    columnas: json[0]?.length || 0,
                    filas: json.length,
                    data: JSON.stringify(json),
                };

                setExcelData(excelData);
                setValue('file', excelData.data);
                setIsModalOpen(true);
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => setIsModalOpen(false);
    return (
        <div className="layout-app user-crud">
            <section className="header_crud">
                <button className="button-back" onClick={() => navigate(-1)}>←</button>
                <h4>CREAR OPERACIÓN</h4>
                <button type="submit" className="button-submit" onClick={handleSubmit(onSubmit)} disabled={isSubmitting}>
                    {isSubmitting ? 'Enviando...' : 'Crear'}
                </button>
            </section>

            <form className="form-user" onSubmit={handleSubmit(onSubmit)}>
                {Object.keys(formFields).map((key) => {
                    const field = formFields[key as keyof FormData];
                    if (!field) return null;
                    if (key === 'ope_tiempo_garantia' && !hasGuarantee) return null;
                    return (
                        <div key={key} className="item-form">
                            <label>{field.label}</label>
                            {field.type === 'textarea' ? (
                                <textarea {...register(key as keyof FormData)} placeholder={field.label}/>
                            ) : field.type === 'select' && key === 'pais' ? (
                                <select {...register(key as keyof FormData)}>
                                    <option value="">Seleccionar País</option>
                                    {paises.map((pais) => (
                                        <option key={pais.paiCodigo} value={pais.paiCodigo}>
                                            {pais.paiNombre}
                                        </option>
                                    ))}
                                </select>
                            ) : field.type === 'select' && key === 'sociedad' ? (
                                <select {...register(key as keyof FormData)}>
                                    <option value="">Seleccionar Sociedad</option>
                                    {sociedades.map((sociedad) => (
                                        <option key={sociedad.socId} value={sociedad.socId}>
                                            {sociedad.socNobre}
                                        </option>
                                    ))}
                                </select>
                            ) : field.type === 'select' && key === 'cliente' ? (
                                <select {...register(key as keyof FormData)}>
                                    <option value="">Seleccionar Cliente</option>
                                    {clientes.map((cliente) => (
                                        <option key={cliente.cliId} value={cliente.cliId}>
                                            {cliente.cliNombre}
                                        </option>
                                    ))}
                                </select>
                            ) : field.type === 'select' && key === 'comercial' ? (
                                <select {...register(key as keyof FormData)}>
                                    <option value="">Seleccionar Comercial</option>
                                    {comerciales.map((comercial) => (
                                        <option key={comercial.comId} value={comercial.comId}>
                                            {comercial.persona.perNombre} {comercial.persona.perApellido}
                                        </option>
                                    ))}
                                </select>
                            ) : field.type === 'select' && key === 'jef_id' ? (
                                <select {...register(key as keyof FormData)}>
                                    <option value="">Seleccionar Jefe de Operación</option>
                                    {jefes.map((jefe) => (
                                        <option key={jefe.jefCodigo} value={jefe.jefCodigo}>
                                            {jefe.persona.perNombre} {jefe.persona.perApellido}
                                        </option>
                                    ))}
                                </select>
                            ) : field.type === 'select' && key === 'ope_bl_id' ? (
                                <select {...register(key as keyof FormData)} onChange={handleBlChange}>
                                    <option value="">Seleccionar BL línea de negocio</option>
                                    {bls.map((bl) => (
                                        <option key={bl.proId} value={bl.proId}>
                                            {bl.proNombre}
                                        </option>
                                    ))}
                                </select>
                            ) : field.type === 'select' && key === 'ope_bu_id' ? (
                                <select {...register(key as keyof FormData)} onChange={handleBuChange}>
                                    <option value="">Seleccionar BU unidad de negocio</option>
                                    {bus.map((bu) => (
                                        <option key={bu.proId} value={bu.proId}>
                                            {bu.proNombre}
                                        </option>
                                    ))}
                                </select>
                            ) : field.type === 'select' && key === 'ope_ol_id' ? (
                                <select {...register(key as keyof FormData)}>
                                    <option value="">Seleccionar Ol organización legal</option>
                                    {ols.map((ol) => (
                                        <option key={ol.proId} value={ol.proId}>
                                            {ol.proNombre}
                                        </option>
                                    ))}
                                </select>
                            ) : field.type === 'select' && key === 'ope_divisa_id' ? (
                                <select {...register(key as keyof FormData)}>
                                    <option value="">Seleccionar Divisa</option>
                                    {divisas.map((divisa) => (
                                        <option key={divisa.proId} value={divisa.proId}>
                                            {divisa.proNombre}
                                        </option>
                                    ))}
                                </select>
                            ) : field.type === 'select' && key === 'ope_tipo_id' ? (
                                <select {...register(key as keyof FormData)}>
                                    <option value="">Seleccionar Tipo de Operación</option>
                                    {tipos.map((tipo) => (
                                        <option key={tipo.proId} value={tipo.proId}>
                                            {tipo.proNombre}
                                        </option>
                                    ))}
                                </select>
                            ) : field.type === 'select' && key === 'ope_garantia' ? (
                                <select {...register(key as keyof FormData)} onChange={handleGuaranteeChange}>
                                    <option value="">Seleccionar validez de garantía</option>
                                    <option value="true">Sí</option>
                                    <option value="false">No</option>
                                </select>
                            ) : hasGuarantee && key === 'ope_tiempo_garantia' ? (
                                <>
                                    <label>¿Tiempo de garantía?</label>
                                    <select {...register(key as keyof FormData)}>
                                        <option value="">Seleccionar tiempo de garantía</option>
                                        {tiempos.map((tiempo) => (
                                            <option key={tiempo.proId} value={tiempo.proId}>
                                                {tiempo.proNombre}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            ) : field.type === 'date' && key === 'ope_fecha_inicio' ? (
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date: Date | null) => {
                                        setStartDate(date);
                                        setValue(key as keyof FormData, date!);
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Selecciona una fecha"
                                />
                            ) : field.type === 'date' && key === 'ope_fecha_fin' ? (
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date: Date | null) => {
                                        setEndDate(date);
                                        setValue(key as keyof FormData, date!);
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Selecciona una fecha"
                                />
                            ) : field.type === 'file' ? (
                                <>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        style={{ display: 'none' }}
                                        accept=".xlsx, .xls, .csv"
                                        onChange={handleFileChange}
                                    />

                                    <div className="file-info-container">
                                        <div className="file-info">
                                            <label htmlFor="fileInput" className="file-upload-label">
                                                <FaRegFileExcel className="excel-icon"/>
                                                {!fileName && (
                                                    <span className="no_bold" >Selecciona archivo P&G</span>
                                                )}
                                            </label>
                                                {fileName && (
                                                    <div className="file-info">
                                                        <span>{fileName.length > 50 ? `${fileName.slice(0, 50)}...` : fileName}</span>
                                                        <FaEye onClick={openModal} className="eye-icon"/>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <input
                                    type={field.type}
                                    {...register(key as keyof FormData)}
                                    placeholder={field.label}
                                />
                            )}
                            {errors[key as keyof FormData]?.message &&
                                <div className="error-message">{errors[key as keyof FormData]?.message}</div>}
                        </div>
                    );
                })}
            </form>
            {isModalOpen && <ModalTable isOpen={isModalOpen} onClose={closeModal} data={JSON.parse(excelData.data)} />}
            <ModalAlert
                isOpen={modalAlertOpen}
                onClose={() => setModalAlertOpen(false)}
                message={alertMessage}
                type={alertType}
            />
        </div>
    );
};

export default CrudProject;
