import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import fetchApi from '../../api/fetchApi';
import { useLocation, useNavigate } from 'react-router-dom';
import './formStyles.css';
import ModalAlert from '../template/ModalAlert';

const validationSchema = Yup.object({
    perNombre: Yup.string().min(4, 'Name must be at least 4 characters').required('Este campo es requerido'),
    perApellido: Yup.string().min(3, 'Last name must be at least 3 characters').required('Este campo es requerido'),
    perDocumento: Yup.string().min(6, 'Document must be at least 6 characters').required('Este campo es requerido'),
    perCorreo: Yup.string().email('Invalid email address').matches(/^[^\s@]+@inetum\.com$/, 'El correo electrónico debe ser de dominio @inetum.com').required('Este campo es requerido'),
    perTelefono: Yup.string().min(7, 'Phone must be at least 7 characters').max(10, 'Phone number must be at most 10 characters').required('Este campo es requerido'),
    perEstado: Yup.string().required('Este campo es requerido'),
});


type FormField = {
    model: string;
    label: string;
    type: 'text' | 'number' | 'select';
    items?: { label: string; value: string }[];
};

type FormState = Record<string, FormField>;

const formPerson: FormState = {
    perNombre: { model: '', label: 'Nombre', type: 'text' },
    perApellido: { model: '', label: 'Apellido', type: 'text' },
    perDocumento: { model: '', label: 'Documento', type: 'number' },
    perCorreo: { model: '', label: 'Correo electronico', type: 'text' },
    perTelefono: { model: '', label: 'Numero de telefono', type: 'number' },
    perEstado: { model: '', label: 'Estado', type: 'select', items: [{ label: 'Activo', value: '1' }, { label: 'Inactivo', value: '0' }] }
};

type FormValues = Record<string, any>;

const CreatePerson: React.FC<{ endpoint: string; mode: 'create' | 'edit' }> = ({ endpoint, mode }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [form, setForm] = useState<FormState>(formPerson);
    const [values, setValues] = useState<FormValues>({});
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [modalAlertOpen, setModalAlertOpen] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertType, setAlertType] = useState<'success' | 'error'>('success');

    useEffect(() => {
        if (mode === 'edit' && location.state?.user) {
            const person = location.state.user;
            setValues({
                ...person,
                perEstado: person.perEstado ? '1' : '0'
            });
        }
    }, [mode, location.state?.user]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const validate = () => {
        try {
            validationSchema.validateSync(values, { abortEarly: false });
            setErrors({});
            return true;
        } catch (validationErrors) {
            if (validationErrors instanceof Yup.ValidationError) {
                const formattedErrors = validationErrors.inner.reduce((acc: Record<string, string>, err: Yup.ValidationError) => {
                    acc[err.path as string] = err.message;
                    return acc;
                }, {});
                setErrors(formattedErrors);
            } else {
                console.error('Unexpected error during validation:', validationErrors);
            }
            return false;
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!validate()) {
            setIsSubmitting(false);
            return;
        }

        const transformedValues = {
            perApellido: values.perApellido,
            perCorreo: values.perCorreo,
            perDocumento: values.perDocumento,
            perEstado: parseInt(values.perEstado, 10),
            perId: location.state.user?.perId || 0,
            perNombre: values.perNombre,
            perTelefono: values.perTelefono
        };

        const requestMethod = mode === 'create' ? 'POST' : 'PUT';
        const url = mode === 'create' ? endpoint : `${endpoint}`;

        try {
            const response = await fetchApi({
                endpoint: url,
                method: requestMethod,
                body: transformedValues,
                useToken: true
            });

            if (response) {
                setAlertMessage(`Persona ${mode === 'create' ? 'creada' : 'actualizada'} exitosamente.`);
                setAlertType('success');
                setTimeout(() => {
                    navigate('/users');
                }, 3000);
            } else {
                setAlertMessage(`Error ${mode === 'create' ? 'creando' : 'actualizando'} la persona, revisa los datos.`);
                setAlertType('error');
            }
        } catch (error) {
            setAlertMessage(`Error ${mode === 'create' ? 'creando' : 'actualizando'} la persona, revisa los datos.`);
            setAlertType('error');
        } finally {
            setTimeout(() => {
                setModalAlertOpen(true);
            }, 0);
            setIsSubmitting(false);
        }
    };

    return (
        <div className="layout-app user-crud">
            <section className="header_crud">
                <button className="button-back" onClick={() => navigate(-1)}>←</button>
                <h4>{mode === 'create' ? 'Crear Persona' : 'Editar Persona'}</h4>
                <button type="submit" className="button-submit" onClick={handleSubmit} disabled={isSubmitting}>
                    {isSubmitting ? 'Enviando...' : mode === 'create' ? 'Crear' : 'Editar'}
                </button>
            </section>

            <form className="form-user" onSubmit={handleSubmit}>
                {Object.keys(form).map(key => (
                    <div key={key} className="item-form">
                        <label htmlFor={key}>{form[key].label}</label>
                        {form[key].type === 'text' || form[key].type === 'number' ? (
                            <input
                                type={form[key].type}
                                name={key}
                                placeholder={form[key].label}
                                value={values[key] || ''}
                                onChange={handleChange}
                                onBlur={() => validate()}
                                disabled={mode === 'edit' && key === 'perCorreo'}
                            />
                        ) : form[key].type === 'select' ? (
                            <select name={key} value={values[key] || ''} onChange={handleChange}>
                                <option value="">Seleccionar...</option>
                                {form[key].items?.map(item => (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        ) : null}
                        {errors[key] && <div className="error-message">{errors[key]}</div>}
                    </div>
                ))}
            </form>
            <ModalAlert
                isOpen={modalAlertOpen}
                onClose={() => setModalAlertOpen(false)}
                message={alertMessage}
                type={alertType}
            />
        </div>
    );
};

export default CreatePerson;
