import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/template/Layout';
import Home from "./components/home/home";
import KnowledgeMap from './components/knowledgeMap/knowledgeMap';
import Ha from './components/HA/ha';
import UserList from './components/user/UserList';
import LoginForm from './components/login/LoginForm';
import ProtectedRoute from './components/ProtectedRoute';
import NotFound from './pages/NotFound';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import CreatePerson from "./components/user/CrudPerson";
import CreateUser from "./components/user/Crud";
import Dashboard from "./components/operations/Dashboard";
import CrudProject from "./components/operations/crudProyect";
const App: React.FC = () => (
    <Router>
        <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route element={<Layout />}>
                <Route element={<ProtectedRoute />}>
                    <Route path="/home" element={<Home />} />
                    <Route path="/knowledgeMap" element={<KnowledgeMap />} />
                    <Route path="/users" element={<UserList />} />
                    <Route path="/ha" element={<Ha />} />
                    <Route path="/operation/dashboard" element={<Dashboard />} />
                    <Route path="/crud/create" element={<CreateUser endpoint="/usuarios" mode="create" />} />
                    <Route path="/crud/createPersona" element={<CreatePerson endpoint="/personas" mode="create" />} />
                    <Route path="/crud/edit/:id" element={<CreateUser endpoint="/usuarios" mode="edit" />} />
                    <Route path="/crud/editPersona/:id" element={<CreatePerson endpoint="/personas"  mode="edit" />} />
                    <Route path="/crud/createProyect" element={<CrudProject />} />
                </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    </Router>
);

export default App;
